export const Section = ({ title }) => {
  const marginSection = { marginBottom: "30px" };

  return (
    <div>
      <div style={marginSection}></div>
      <h4>{title}</h4>
      <div style={marginSection}></div>
      <hr />
    </div>
  );
};
