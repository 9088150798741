import { CustomCard } from "components/CustomCard";
import { Section } from "components/Section";

export const Experience = () => {
  return (
    <div>
      <Section title="software engineering" />
      <CustomCard
        title="Standard Chartered Bank"
        subtitle1="Software Engineering Intern, May 2024 - August 2024"
        description={[
          "I interned at ",
          <a className="underline" href="https://www.sc.com/">
            Standard Chartered Bank
          </a>,
          " with the Corporate & Institutional Banking, E-Commerce, Blade team. ",
          "I developed fullstack features on the ",
          <a
            className="underline"
            href="https://www.sc.com/en/corporate-investment-banking/markets-platform/"
          >
            Standard Chartered Markets
          </a>,
          " platform that support pricing and trading for cross asset derivatives products.",
          " I also refactored core backend services used by numerous backend-for-frontend services",
          " to improve scalability.",
        ]}
      />
      <CustomCard
        title="Shopee"
        subtitle1="Data Engineering Intern, January 2024 - April 2024"
        description={[
          "I interned at ",
          <a className="underline" href="https://shopee.com/">
            Shopee
          </a>,
          " (part of the ",
          <a className="underline" href="https://www.sea.com/home">
            Sea Group
          </a>,
          ") with the Marketplace, Regional Sellers team. ",
          "I designed schemas and optimised queries to process Shopee traffic data for ",
          <a className="underline" href="https://seller.shopee.sg/edu/home">
            Seller Education Hub
          </a>,
          " and ",
          <a
            className="underline"
            href="https://seller.shopee.sg/edu/article/10153/about-seller-missions"
          >
            Seller Missions
          </a>,
          ". I built data pipelines processing sellers' metrics data for ",
          <a
            className="underline"
            href="https://seller.shopee.sg/edu/article/7048"
          >
            Preferred Sellers
          </a>,
          ".",
        ]}
      />
      <CustomCard
        title="HTX"
        subtitle1="Software Engineering Intern, May 2023 - August 2023"
        description={[
          "I interned at ",
          <a className="underline" href="https://www.htx.gov.sg/">
            Home Team Science & Technology Agency
          </a>,
          ", where I developed fullstack features on HTX's Cloud Evidence Portal on Government Commercial Cloud to provide digital services to the public service.",
          " I also built infrastructure-as-code deployment architecture for microservices.",
        ]}
      />
      <CustomCard
        title="Tinkertanker"
        subtitle1="Software Engineering Intern, May 2022 - August 2022"
        description={[
          "I interned at ",
          <a className="underline" href="https://tinkertanker.com/">
            Tinkertanker
          </a>,
          ", where I developed fullstack features on the course management portal ",
          <a className="underline" href="https://trump.tk.sg/">
            Trump
          </a>,
          ", and wrote scripts for common use-case queries. I also developed frontend features on the learning management system ",
          <a className="underline" href="https://www.echoclass.com/">
            EchoClass
          </a>,
          ".",
        ]}
      />
      <Section title="teaching" />
      <CustomCard
        title="Software Development Project"
        subtitle1="National University of Singapore, Summer 2023, Summer 2024"
        description={[
          "I was a teaching assistant for two iterations of ",
          <a
            className="underline"
            href="https://nusmods.com/modules/CP2106/independent-software-development-project-orbital"
          >
            CP2106: Software Development Project
          </a>,
          " taught by Professor Zhao Jin. My responsibilities included teaching software engineering practices, providing sprint consultations and grading submissions.",
        ]}
      />
    </div>
  );
};
