import profilepic from "assets/img/profilepic.jpg";
import { Image } from "react-bootstrap";

const greetingMargin = {
  marginTop: "30px",
};

const profilePicWidth = {
  maxWidth: "150px",
  height: "auto",
};

const profileDescriptionMargin = {
  marginTop: "30px",
  zIndex: -1,
};

const pictureContainer = {
  "flex-direction": "column",
  "justify-content": "center",
  "align-items": "center",
  "text-align": "center",
};

const textColor = {
  color: "#4f4f4f",
};

export const Home = () => {
  return (
    <div>
      <div style={pictureContainer}>
        <Image style={profilePicWidth} src={profilepic} roundedCircle />
        <h4 style={greetingMargin}>hello!</h4>
      </div>
      <div fluid style={profileDescriptionMargin}>
        <p style={textColor}>
          I'm Donovan. I live in Singapore and am currently a third year student
          at the{" "}
          <a href="https://nus.edu.sg" className="underline">
            National University of Singapore (NUS)
          </a>{" "}
          majoring in Computer Science. I've especially enjoyed learning about
          distributed algorithms in university. While in NUS, I also studied
          under the{" "}
          <a href="https://www.usp.nus.edu.sg/" className="underline">
            University Scholars Programme (USP)
          </a>
          , where I wrote essays on various topics which you may view{" "}
          <a
            href="https://github.com/Donovan9617/USP_Papers"
            className="underline"
          >
            here
          </a>
          .
        </p>
        <p style={textColor}>
          I was also a tech lead in{" "}
          <a href="https://nususc.com/about" className="underline">
            USDevs
          </a>
          , a student tech club in USP aimed at harnessing tech to improve
          college life.
        </p>
        <p style={textColor}>
          I enjoy solving problems for the community, and am passionate about
          challenging and meaningful software solutions that improve people's
          lives.
        </p>
        <p style={textColor}>
          Outside of work, I love reading books, going to the park, playing
          table tennis, and travelling the world.
        </p>
      </div>
    </div>
  );
};
