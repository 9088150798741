export const CustomCard = ({ title, subtitle1, subtitle2, description }) => {
  const customCardMargin = { marginBottom: "30px" };
  const styleSubtitle1 = { fontSize: "14px", color: "#5f5f5f" };
  const textColor = { color: "#4f4f4f" };

  return (
    <div>
      <div style={customCardMargin}></div>
      <h5>{title}</h5>
      <p style={styleSubtitle1}>
        {subtitle1}
        <br />
        {subtitle2}
      </p>
      <p style={textColor}>{description}</p>
      <div style={customCardMargin}></div>
      <hr style={{ color: "white" }} />
    </div>
  );
};
