import "app/App.css";
import { NavBar } from "components/NavBar";
import { Experience } from "pages/Experience";
import { Home } from "pages/Home";
import { Projects } from "pages/Projects";
import { Container } from "react-bootstrap";
import { BrowserRouter, Route } from "react-router-dom";

const appMaxWidth = 650;
const containerStyle = {
  maxWidth: appMaxWidth,
  margin: "0 auto",
  marginTop: "50px",
};
const bottomStyle = {
  marginBottom: "200px",
};
const navBarMargin = { marginTop: "60px" };

export const App = () => {
  return (
    <Container style={containerStyle}>
      <BrowserRouter>
        <NavBar />
        <div style={navBarMargin}>
          <Route exact path="/" component={Home} />
          <Route path="/experience" component={Experience} />
          <Route path="/projects" component={Projects} />
        </div>
        <div style={bottomStyle} />
      </BrowserRouter>
    </Container>
  );
};
