import resume from "assets/pdf/Resume.pdf";
import { useEffect } from "react";
import { FiGithub, FiLinkedin, FiMail } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

export const NavBar = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = `${getPageTitle(location.pathname)} Donovan Singh`;
  }, [location.pathname]);

  const getPageTitle = (path) => {
    switch (path) {
      case "/experience":
        return "Experience |";
      case "/projects":
        return "Projects |";
      default:
        return "";
    }
  };

  const navStyle = {
    display: "flex",
  };

  const navGroupStyle = {
    marginRight: "auto",
  };

  const iconGroupStyle = {
    marginLeft: "auto",
    flexDirection: "row",
    justifyContent: "center",
  };

  const iconStyle = {
    marginLeft: "15px",
    color: "black",
    marginBottom: "3px",
  };

  const navLinkStyle = {
    color: "black",
    marginRight: "15px",
    textDecoration: "none",
    fontFamily: "Lato, sans-serif",
    fontSize: "15px",
  };

  return (
    <div>
      <nav style={navStyle}>
        <div style={navGroupStyle}>
          <Link to="/" style={navLinkStyle}>
            home
          </Link>
          <Link to="/experience" style={navLinkStyle}>
            experience
          </Link>
          <Link to="/projects" style={navLinkStyle}>
            projects
          </Link>
          <a
            href={resume}
            style={navLinkStyle}
            target="_blank"
            rel="noreferrer"
          >
            resume
          </a>
        </div>
        <div style={iconGroupStyle}>
          <a href="mailto:don9617@hotmail.com">
            <FiMail style={iconStyle} />
          </a>
          <a href="https://github.com/Donovan9617">
            <FiGithub style={iconStyle} />
          </a>
          <a href="https://www.linkedin.com/in/donovan-singh-361a9b207/">
            <FiLinkedin style={iconStyle} />
          </a>
        </div>
      </nav>
    </div>
  );
};
