import { CustomCard } from "components/CustomCard";
import { Section } from "components/Section";

export const Projects = () => {
  return (
    <div>
      <Section title="projects" />
      <CustomCard
        title="DuckPay"
        subtitle1="TypeScript | React"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/duckpay"
          >
            GitHub
          </a>
        }
        description={[
          "DuckPay is a digital wallet for making and receiving payments by using tokens and QR, without the need for bank accounts, credit cards, or personal accounts.",
        ]}
      />
      <CustomCard
        title="ScamWatch"
        subtitle1="TypeScript | React"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/scamwatch-frontend"
          >
            GitHub
          </a>
        }
        description={[
          "ScamWatch is a tool which provides various scam case file management features, data visualisation charts, and simple scam statistics.",
        ]}
      />
      <CustomCard
        title="SEPlan"
        subtitle1="Django | CSS"
        subtitle2={
          <a className="underline" href="https://github.com/Donovan9617/SEPlan">
            GitHub
          </a>
        }
        description={[
          "SEPlan is a web app helping students prepare for overseas student exchange with event calendar, community reviews, and module mapping.",
        ]}
      />
      <CustomCard
        title="DevEnable"
        subtitle1="Java"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/DevEnable"
          >
            GitHub
          </a>
        }
        description={[
          "DevEnable is an app using command line interface with task management features for software developers to manage their projects, issues, and clients.",
        ]}
      />
      <CustomCard
        title="Cinnabot"
        subtitle1="Python | Telegram Bot API"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/Cinnabot"
          >
            GitHub
          </a>
        }
        description={[
          "Cinnabot is a chatbot serving residents of NUS College, offering features for booking common spaces, submitting fund requests, and sending feedback to committees.",
        ]}
      />
      <CustomCard
        title="Aiducation"
        subtitle1="React | Django"
        subtitle2={
          <a
            className="underline"
            href="https://github.com/Donovan9617/Aiducation"
          >
            GitHub
          </a>
        }
        description={[
          "Aiducation is a classroom feedback and analytics tool for teachers to receive real time anonymous feedback from students in the form of reactions and questions.",
        ]}
      />
    </div>
  );
};
